<template>
  <div class="login animated fadeIn">
    <loading :active.sync="isLoading"></loading>
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow topContainer cardLogin">
        <b-row class="loginVpsLogo">
          <b-col sm="12">
            <div class="text-center mb-5">
              <img src="/img/logo_vps_green.png" class="img-fluid mx-auto d-block "/>
            </div>
          </b-col>
        </b-row>
          <b-row>
            <b-col md="6" sm="12" xl="6">
              <b-card class="p-4" no-body style="height: 330px">
                <b-card-body>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="alert alert-danger" v-if="$v.form.$error">
                        {{$t('message.login_errors_text')}}
                      </div>
                    </div>
                  </div>

                  <b-form v-on:submit.prevent="submit">
                    <h1>{{$t('message.login_text')}}</h1>
                    <p class="text-muted">{{$t('message.login_credential_request_text')}}</p>
                    <b-input-group :class="{ 'hasError': $v.form.email.$error }" class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input :placeholder="$t('message.placeholder_username')"
                                    class="form-control" name="email"
                                    type="text"
                                    v-model="form.email"/>
                    </b-input-group>
                    <b-input-group :class="{ 'hasError': $v.form.password.$error }" class="mb-4">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        :placeholder="$t('message.placeholder_password')" class="form-control"
                        name="password"
                        type="password" v-model="form.password"/>
                    </b-input-group>
                    <b-row>
                      <b-col class="text-left" cols="12" style="margin-bottom: 5px">
                        <b-button block class="bg-theme-secondary px-4 col-sm-12" type="submit">
                          {{$t('message.login_text')}}
                        </b-button>
                      </b-col>
                      <b-col class="text-right" cols="12">
                        <router-link class="txtwhite" :to="{name : 'resetPassword'}">
                          <b-button block class="px-0 col-sm-12" variant="secondary">
                            {{$t('message.password_forgotten_text')}}
                          </b-button>
                        </router-link>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="6" sm="12" xl="6">
              <b-card class="py-5" no-body style="height: 330px">
                <b-card-body class="text-center">
                  <div>
                    <h2>{{$t('message.register_text')}}</h2>
                    <p>{{$t('message.register_now_text')}}</p>
                    <b-col class="" cols="12" style="margin-bottom: 15px">
                      <router-link class="txtwhite" :to="{name : 'preRegister'}">
                        <b-button block class="bg-theme-secondary mt-3 col-sm-12" >
                          {{$t('message.button_proceed_text')}}
                        </b-button>
                      </router-link>
                    </b-col>
                    <b-col cols="12" v-if="isPendingUser">
                      <router-link
                        class="txtwhite"
                        :to="{name : 'sendRegistrationToken'}"
                      >
                        <b-button block class="bg-theme-secondary px-4 col-sm-12 " >
                          {{$t('message.confirm_email_resend')}}
                        </b-button>
                      </router-link>
                    </b-col>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
      </div>
    </div>
  </div>
</template>
<script>

  import {required} from 'vuelidate/lib/validators'
  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';
  import I18n from "../components/i18n/I18n";

  export default {
    name: 'login',
    components: {
      Loading
    },
    data() {
      return {
        currentUser: {},
        countries: [],
        pageMessage: null,
        isLoading: false,
        isLoginFromCart: localStorage.getItem('rowCartLocalStorage') !== null,
        form: {
          email: null,
          password: null,
          cartNumber: null,
        }
      };
    },
    computed: {
      isPendingUser() {
        //let currentUser = await this.$store.dispatch('user/current');
        return this.currentUser.pending === 1;
      },
    },
    validations: {
      form: {
        email: {
          required,
        },
        password: {
          required
        }
      }
    },
    methods: {
      async submit() {
        let cartNumber = this.$store.getters['cart/getPreserveCartNumber'];
        if (cartNumber) {
          this.form.cartNumber = cartNumber;
        }
        this.$v.form.$touch();
        if (this.$v.form.$error) {
          return;
        }
        this.isLoading = true;
        let user = await this.$store.dispatch('user/login', this.form);
        this.currentUser = user;
        if (user.hasOwnProperty('error')) {
          this.$store.dispatch('alerts/setAlert', {
            type: 'danger',
            text: user.error
          });
          this.isLoading = false;
        } else {
          I18n.setCurrentLang(this.currentUser.lang, false);
          if (user.pending === 1) {
          } else {
            await this.$store.dispatch('alerts/setAlert', {
              type: 'success',
              text: this.$t('message.login_successfull')
            });
          }
          this.isLoading = false;
          let cart = await this.$store.dispatch('cart/get');
          let cartNumber = this.$store.getters['cart/getCartNumber'];
          if (user.passwordStatus === 'password expired') {
            await this.$store.dispatch('alerts/setAlert', {
              'type': 'warning',
              'text': this.$t('message.force_change_password')
            });
            //location.href = '/account/change-password';
            this.$router.push({
              name: 'changePassword', params: {
                lang: this.$store.state.user.lang
              }
            });
          } else {
            switch(true) {
              /* utente di tipo CLIENTE */
              case user.customer === 1 :
                if (cartNumber && cartNumber.length) {
                  this.$router.push({
                    name: 'checkout', params: {
                      lang: this.$store.state.user.lang
                    }
                  });
                } else {
                  this.$router.push({
                    name: 'categories', params: {
                      lang: this.$store.state.user.lang
                    }
                  });
                }
                break;
              /* utente che deve terminare la compilazione del profilo */
              case user.registered === 1 :
                this.$router.push({
                  name: 'register', params: {
                    lang: this.$store.state.user.lang
                  }
                });
                break;
              /* utente che deve ancora confermare l'iscrizione */
              case user.pending === 1 :
                this.$router.push({
                    name: 'login', params: {
                    lang: this.$store.state.user.lang
                  }
                });
                break;
            }

            /*
            if (cartNumber && cartNumber.length && user.pending !== 1) {
              this.$router.push({
                name: 'checkout', params: {
                  lang: this.$store.state.user.lang
                }
              });
            } else {
              if (user.pending === 1) {
                this.$router.push({
                  name: 'login', params: {
                    lang: this.$store.state.user.lang
                  }
                });
              } else {

                this.$router.push({
                  name: 'products', params: {
                    lang: this.$store.state.user.lang
                  }
                });
              }

            }
            */
          }
        }
      },
    },
    created() {

    },
    mounted() {


    },
  };
</script>

<style scoped>
  .hasError label {
    color: red;
  }
</style>


<style type="text/css">

  html, body {
    height: 100%;
  }

  body {
    background-image: url("/img/background.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #999;
  }

  div, body {
    margin: 0;
    padding: 0;

  }

  .login {
    height: 100%;
    width: 100%;
  }

  @media only screen
  and (min-device-width : 320px)
  and (max-device-width : 480px) {
    .topContainer {
      margin-top: 0 !important;
    }
    .loginVpsLogo {
      display: none !important;
    }
    .cardLogin {
      box-shadow: none !important;
      border: 0px !important;
      margin-top: 0 !important;
    }
  }


</style>


